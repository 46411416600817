import {
  FORM_VALID,
  type ValidationContext,
  type ValidationResult,
  matchingRegExp,
  required,
} from '@ds/components/Form'

export function validateFrenchPhoneNumber<Values extends object>(
  requiredErrorMessage: string,
  invalidErrorMessage: string,
) {
  return (
    value: unknown,
    values: Values,
    context: ValidationContext,
  ): ValidationResult => {
    const requiredValidation = required(requiredErrorMessage)(
      value,
      values,
      context,
    )

    if (requiredValidation !== FORM_VALID) return requiredValidation

    const frenchPhoneNumberRegex = /^\+33[6-7]{1}[0-9]{8}$/

    const frenchNumberValidation = matchingRegExp(
      frenchPhoneNumberRegex,
      invalidErrorMessage,
    )(value, values, context)

    return frenchNumberValidation
  }
}
