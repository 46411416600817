import type { ProblemValidationErrorMember } from '@backmarket/http-api'

export function getAPIValidationError<Values extends object = never>(
  httpApiErrors: ProblemValidationErrorMember[],
  formField: keyof Values,
  errorType: string,
  errorMessage: string,
) {
  const hasTargettedError = httpApiErrors.some(
    ({ type, target }) => target === formField && type === errorType,
  )

  return hasTargettedError ? errorMessage : undefined
}
