import type { MarketCountryCode } from '@backmarket/http-api'
import {
  getCountryCodeFromPhoneNumber,
  getPhoneNumberAsE164,
} from '@ds/components/InputPhone'

export function getFormattedPhoneNumber(
  defaultCountry: MarketCountryCode,
  dialCode?: string,
  phoneNumber?: string,
) {
  if (!phoneNumber || !dialCode) {
    return null
  }

  const fullPhoneNumber = `${dialCode}${phoneNumber}`
  const countryCode =
    getCountryCodeFromPhoneNumber(fullPhoneNumber) || defaultCountry

  return getPhoneNumberAsE164(fullPhoneNumber, countryCode)
}
